.timeline {
  display: flex;
  flex-direction: column;
  margin: 70px 0px;
  padding: 0 2rem;
  position: relative;

  &__event {
    margin-bottom: $spacing;
    position: relative;
    display: flex;
    gap: 10px;
    margin: $spacing 0;
    border-radius: $bdrs;
    align-self: center;
    width: min(80%, 35rem);
    min-height: 16.5rem;

    &__title {
      font-size: 1.2rem;
      line-height: 1.4;
      text-transform: uppercase;
      font-weight: 600;
      color: #000;
      letter-spacing: 1.5px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $spacing;
      box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
        0 18px 36px -18px rgba(0, 0, 0, 0.3),
        0 -12px 36px -8px rgba(0, 0, 0, 0.025);
      background: #fff;
      width: 100%;
      border-radius: 0 $bdrs $bdrs 0;
    }
    &__date {
      color: $color2;
      font-size: 1.5rem;
      font-weight: 600;
      background: $color1;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 3rem;
      border-radius: $bdrs;
    }

    &__description {
      flex-basis: 60%;
    }

    &--type2 {
      &:after {
        background: $color4;
      }
      .timeline__event__date {
        color: $color3;
        background: $color4;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color3;
          }
        }
      }

      .timeline__event__icon {
        background: $color3;
        color: $color4;
        &:before,
        &:after {
          background: $color3;
        }
      }
      .timeline__event__title {
        color: #000;
      }
    }

    &--type3 {
      &:after {
        background: $color5;
      }
      .timeline__event__date {
        color: $color6;
        background-color: $color5;
      }

      &:nth-child(2n + 1) {
        .timeline__event__icon {
          &:before,
          &:after {
            background: $color6;
          }
        }
      }

      .timeline__event__icon {
        background: $color6;
        color: $color5;
        &:before,
        &:after {
          background: $color6;
        }
      }
      .timeline__event__title {
        color: #000;
      }
    }
    &:last-child {
      .timeline__event__icon {
        &:before {
          content: none;
        }
      }
    }
  }
}

.timeline .timeline__event:nth-child(2) {
  align-self: flex-end;
}

.timeline .timeline__event:nth-child(4) {
  align-self: flex-start;
}

@media (max-width: 786px) {
  .timeline {
    padding: 0;
  }
  .timeline__event {
    flex-direction: column;
    align-self: center;
    &__content {
      width: 100%;
    }

    &__icon {
      border-radius: $bdrs $bdrs 0 0;
      width: 100%;
      margin: 0;
      box-shadow: none;

      &:before,
      &:after {
        display: none;
      }
    }
    &__date {
      border-radius: 0;
      padding: $spacing;
    }

    &:nth-child(2n + 1) {
      flex-direction: column;
      align-self: center;

      .timeline__event__date {
        border-radius: 0;
        padding: $spacing;
      }

      .timeline__event__icon {
        border-radius: $bdrs $bdrs 0 0;
        margin: 0;
      }
    }
  }
  .timeline .timeline__event:nth-child(2) {
    align-self: center;
  }

  .timeline .timeline__event:nth-child(4) {
    align-self: center;
  }
}

@keyframes fillLeft {
  100% {
    right: 100%;
  }
}

@keyframes fillTop {
  100% {
    top: 100%;
  }
}

@keyframes fillLeftOdd {
  100% {
    left: 100%;
  }
}
