.vision-mission-section {
  background-color: #f8f8f8;
  padding: 40px 0;
  overflow: hidden;
}

.container {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  /* animation: moveSection 10s linear infinite; */
}

/* @keyframes moveSection {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0%);
  }
} */

.card {
  width: 90%;
  max-width: 50rem;
  background-color: #4a55a2;
  padding: 30px;
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s ease;
  /* animation: moveSection 10s linear infinite; */
}
/* .card-content {
  height: 100%;
}
.animated-paragraph {
  height: 200px;
} */

.card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover:before {
  opacity: 1;
}
/* 
@keyframes leftToCenter {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes rightToCenter {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.left-to-center {
  animation: leftToCenter 0.8s ease forwards;
}

.right-to-center {
  animation: rightToCenter 0.8s ease forwards;
} */

.left-to-center {
  transform: translateX(-200%);
}

.right-to-center {
  transform: translateX(200%);
}
