//== Template Main Color
$color-white: #fff;

//== social media colors
$facebook: #3b5999;
$twitter: #55acee;
$instagram: #e4405f;
$youtube: #cd201f;
$pinterest: #bd081c;

//timeline
$content-width: calc(40vw - 84px);
$margin: 20px;
$spacing: 20px;
$bdrs: 6px;
$circle-size: 40px;
$icon-size: 32px;
$bdrs-icon: 100%;

$color1: #8ce8db;
$color2: #fff;

$color3: #fff;
$color4: #8ce8db;

$color5: #8ce8db;
$color6: #fff;

/*-----------------
    # Typography
-----------------*/

// @import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&family=Yantramanav:wght@300;400;500;700;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Monda:wght@400;700&display=swap');

//  transition
$transition: all 0.3s ease-in;
$transition-long: all 0.5s ease-in;
$box-shadow: 0px 3px 20px rgba(0, 33, 71, 0.06);
$box-shadow2: 0px 10px 30px rgba(0, 33, 71, 0.08);
